import { Button, ConfigProvider, Tooltip, getLocale, getLang, zh_CN, en_US } from '@didi/ec-base';
import { DHRTable } from '@didi/ec-ehr-base';
import { Intl } from '@didi/pagelets-sdk';
import { DownThickOutlined, UpThickOutlined } from '@didi/iconflow/ec';
import * as formilyAntd from '@formily/antd';
import React, { useEffect, useRef, useState } from 'react';
import {
  ExportButton,
  FormFilter,
  PeopleSelectWithAuth,
  EmptyRender
} from './components';
import { recordPageFMP } from './utils/performance';
import newRequest from './utils/newRequest';
import { showEllipseWord } from './utils/showEllipseWord';
import PageletApi from '@didi/pagelets-sdk';
import manifest from '../manifest.json';
import dayjs from 'dayjs';
const localLocales = manifest.locales || {};

import useStyle from './style';

const { useFormTableQuery, FormPath, createFormActions } = formilyAntd;

const fitteractions = createFormActions();

let startLoadTime;

const Members = (props) => {
  const {
    auth, headers, apiHost, locales, emplValue, emplLabel, deptCodes, pageCode, deptInfos, type
  } = props.params;

  const isCTR = type === 'CTR';
  const isOutSourcing = type === 'OUTSOURCING'; // 工时制外包
  const authId = PageletApi.utils.getFunctionId(auth, isOutSourcing ? 'page_outsourcing_manhour_employees' : 'page_team_members');
  const newHeaders = { ...headers, 'bricks-function-id': authId };
  const request = newRequest.init({
    ...headers,
    'bricks-function-id': authId
  });

  const [paramsValue, setParamsValue] = useState(0);
  const [params, setParams] = useState();
  const [tableWidth, setTableWidth] = useState(0);
  const [smallJobCategoryDisable, setSmallJobCategoryDisable] = useState(true);
  const [searchCriteria, setsearchCriteria] = useState(true); // 搜索条件

  const [isFirstFetch, setIsFirstFetch] = useState(true);
  const [postData, setPostData] = useState(null);
  const hasOnlyOneAuthDept = useRef([]);

  // 多语言优先 props，再取本地
  const langLocales = locales || localLocales;
  const locale = getLocale(langLocales);
  const intl = Intl(locale);
  const langEn = getLang()?.toLowerCase().includes('us');

  // 员工性质
  const outSourcingEmployeeNature = [
    {
      label: intl('OUT_HRO'),
      value: 'OUT_HRO'
    },
    {
      label: intl('CTS_HRO'),
      value: 'CTS_HRO'
    },
    {
      label: intl('XINGHUO_HRO'),
      value: 'XINGHUO-HRO'
    },
    {
      label: intl('OS_HRO'),
      value: 'OS_HRO'
    }
  ];

  // 员工类型
  const outSourcingEmployeeType = [
    {
      label: 'HC类外包-组织费用',
      value: 22
    },
    {
      label: 'HC类外包-业务费用',
      value: 23
    }
  ];
  const outSourcingEmployeeNatureMap = {
    'OUT_HRO': intl('OUT_HRO'),
    'CTS_HRO': intl('CTS_HRO'),
    'XINGHUO-HRO': intl('XINGHUO_HRO'),
    'OS_HRO': intl('OS_HRO'),
  }
  const outSourcingEmployeeTypeMap = {
    '22': intl('ORG_EXPENSE'),
    '23': intl('BUSINESS_EXPENSE')
  }
   // 沧海档案链接，注；只提供了测试、预发、线上的地址
   const profile = {
    dev: '//canghai.mix-test.didichuxing.com/talent/view',
    test: '//canghai.mix-test.didichuxing.com/talent/view',
    stage: '//canghai-stable.didichuxing.com/talent/view',
    uat: '//canghai.mix-test.didichuxing.com/talent/view',
    online: '//canghai.didichuxing.com/talent/view',
  };
  const getEnv = () => {
    if (/.*dev.*/.test(location.hostname)) return 'dev';
    if (/.*test.*/.test(location.hostname)) return 'test';
    if (/.*stage.*/.test(location.hostname)) return 'stage';
    if (/.*uat.*/.test(location.hostname)) return 'uat';
    return 'online';
  };


  const columns = [
    {
      title: intl('USERNAME'),
      dataIndex: 'name',
      key: 'name',
      width: 100,
      ellipsis: true,
      render: (text, record) => (
        <Tooltip placement="left" title={record.name}>
          {pageCode === 'hr-workspace' && (
            <Button type="link" size="small" style={{ padding: 0, fontSize: '12px' }} onClick={() => {
              window.open(`/hr-workspace/ei/staffinfo/detail?id=${record.emplid}&roleCode=HRBP`);
            }}>{text}</Button>
          )}
          {location.pathname.startsWith('/management') && (
            <Button type="link" size="small" style={{ padding: 0, fontSize: '12px' }} onClick={() => {
              window.open(`/management/team/members/ei/staffinfo?id=${record.emplid}&roleCode=Manager`);
            }}>{text}</Button>
          )}
          {
            (pageCode !== 'hr-workspace' && !location.pathname.startsWith('/management')) && (
              <Button type="link" size="small" style={{ padding: 0, fontSize: '12px' }} onClick={() => {
                window.open(`/home/team/members/ei/staffinfo?id=${record.emplid}&roleCode=Manager`);
              }}>{text}</Button>
            )
          }
        </Tooltip>
      ),
      fixed: 'left',
    },
    {
      title: intl('JOB_NUMBER'),
      dataIndex: 'emplid',
      key: 'emplid',
      width: 90,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      ellipsis: true,
      render: (text, record) => (
        <Tooltip title={text}>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: intl('EMAIL'),
      dataIndex: 'emailAddr',
      key: 'emailAddr',
      width: 210,
      ellipsis: true,
      render: (text, record) => (
        <Tooltip title={text}>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: intl('DEPARTMENT_PATH'),
      dataIndex: 'deptPath',
      key: 'deptPath',
      width: 300,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      ellipsis: true,
      render: (text, record) => (
        <Tooltip title={text}>
          <span>{showEllipseWord(text, '>', false)}</span>
        </Tooltip>
      ),
    },
    {
      title: intl('DEPARTMENT_NUMBER'),
      dataIndex: 'deptCodePath',
      key: 'deptCodePath',
      width: 80,
      ellipsis: true,
      render: (text, record) => (
        <Tooltip title={text}>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: intl('REPORT_TO'),
      dataIndex: 'deptManagementLdap',
      key: 'deptManagementLdap',
      width: 140,
      ellipsis: true,
      render: (text, record) => (
        <Tooltip title={record.deptManagementLdap}>
          {record.deptManagementLdap}
        </Tooltip>
      ),
    },
    {
      title: intl('INTERN_MENTOR'),
      dataIndex: 'mentorInfo',
      key: 'mentorInfo',
      width: 140,
      ellipsis: true,
      render: (text, record) => (
        <Tooltip title={record.mentorInfo}>
          {record.mentorInfo}
        </Tooltip>
      ),
    },
    {
      title: intl('EMPLOYEE_NATURE'),
      dataIndex: 'emplClassDescr',
      key: 'emplClassDescr',
      width: 80,
      ellipsis: true,
      render: (text, record) => (
        <Tooltip title={text}>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: intl('JOINED_DATE'),
      dataIndex: 'deptEntryDt',
      key: 'deptEntryDt',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      width: 110,
      ellipsis: true,
      render: (text, record) => (
        <Tooltip title={text}>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: intl('SI_LING'),
      dataIndex: 'siLing',
      key: 'siLing',
      width: 60,
      ellipsis: true,
      render: (text, record) => (
        <Tooltip title={text}>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: intl('LARGE_SEQUENCE'),
      dataIndex: 'jobFunctionDescr',
      key: 'jobFunctionDescr',
      width: 100,
      ellipsis: true,
      render: (text, record) => (
        <Tooltip title={text}>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: intl('SMALL_SEQUENCE'),
      dataIndex: 'jobSubFuncDescr',
      key: 'jobSubFuncDescr',
      width: 120,
      ellipsis: true,
      render: (text, record) => (
        <Tooltip title={text}>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: intl('JOB_NAME'),
      dataIndex: 'djobcodeDescr',
      key: 'djobcodeDescr',
      width: 120,
      ellipsis: true,
      render: (text, record) => (
        <Tooltip title={text}>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: intl('RANK'),
      dataIndex: 'supvLvlIdDescr',
      key: 'supvLvlIdDescr',
      width: 80,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      ellipsis: true,
      render: (text, record) => (
        <Tooltip title={text}>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: intl('SEX'),
      dataIndex: 'sexName',
      key: 'sexName',
      width: 60,
      ellipsis: true,
      render: (text, record) => (
        <Tooltip title={text}>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: intl('EDUCATION'),
      dataIndex: 'highestEducLvlDescr',
      key: 'highestEducLvlDescr',
      width: 110,
      ellipsis: true,
      render: (text, record) => (
        <Tooltip title={text}>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: intl('LABOR_CONTRACT_WORKPLACE'),
      dataIndex: 'locationDescr',
      key: 'locationDescr',
      width: 120,
      ellipsis: true,
      render: (text, record) => (
        <Tooltip title={text}>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: intl('PLACE_OF_SOCIAL_SECURITY_PAYMENT'),
      dataIndex: 'cgSilDescr',
      key: 'cgSilDescr',
      width: 100,
      ellipsis: true,
      render: (text, record) => (
        <Tooltip title={text}>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: intl('CONTRACT_SUBJECT'),
      dataIndex: 'companyDescr',
      key: 'companyDescr',
      width: 200,
      ellipsis: true,
      render: (text, record) => (
        <Tooltip title={text}>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: intl('CONTRACT_END_DATE'),
      dataIndex: 'contractEndDt',
      key: 'contractEndDt',
      width: 110,
      ellipsis: true,
      render: (text, record) => (
        <Tooltip title={text}>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: intl('EMPLOYEE_FILE'),
      dataIndex: 'file',
      key: 'file',
      width: 80,
      fixed: 'right',
      ellipsis: true,
      render: (text, record) => {
        let tit= "";
        const useInfo = JSON.parse(localStorage.getItem('userInfo') || '{}')
        const today = dayjs(new Date()).format('YYYY-MM-DD');
        if(dayjs(record?.deptEntryDt).valueOf() === dayjs(today).valueOf()){
          tit = intl('ON_THE_DAY_OF_EMPLOYMENT');
        }
        if(record?.emplid === useInfo?.empId && pageCode === 'hr-workspace'){
          tit = intl('CANNOT_VIEW_FILES');
        }
        if(tit){
          return <Tooltip title={tit}>
            <span style={{color: 'rgba(0, 0, 0, 0.25)'}}>{intl('VIEW')}</span>
          </Tooltip>
        } else {
          return <a href={`${profile[getEnv()]}?empId=${record.emplid}`} rel="noreferrer" target="_blank" className="theme-text" style={{color: '#FF6400'}}>{intl('VIEW')}</a>
        }
      },
    },
    {
      title: intl('TRIAL_PERIOD_DETAILS'),
      dataIndex: 'probation',
      key: 'probation',
      width: 120,
      fixed: 'right',
      ellipsis: true,
      render: (text, record) => {
        //targetStatusCode 试用期目标状态 0:空null 1.进行中、2.已完成、3.审核中、4-未开始
        //probationBusinessStatusCode 单据状态 10:未发起，20:已撤销，30:审批中，40:已拒绝，50:已完成，60:已终止，70:已结束
        const probationStatus = !!record?.probationBusinessStatusCode && record?.probationBusinessStatusCode != 10;
        const path = window.location.pathname.split('/');
        let role = '';
        if(window.location.pathname === '/management'){
          role = 'managementCard';
        } else if(window.location.pathname === '/hr-workspace'){
          role = 'hrWorkspaceCard';
        } else {
          role = pageSource;
        }
        return (
          <span className="team-members-probation-info">
            {
              !probationStatus && (!record.targetStatusCode || record.targetStatusCode === 1 || record.targetStatusCode === 4)
                ? <span />
                : (
                  <a
                    href={probationStatus ? `/${path[1]}/probation/info?empId=${record.probationId}&&role=${role}` : record.targetViewLink}
                    rel="noreferrer"
                    target="_blank"
                    className="theme-text"
                  >
                    {intl('VIEW')}
                  </a>
                )
            }
            {/* 正式展示 */}
            {(record.emplClass === 'CTR' && record.dpositiveStatus === '02')
            && <span className="is-probation">{intl('DURING_THE_PROBATIONARY_PERIOD')}</span>}
          </span>
        );
      },
    },
  ];
  const outSourcingColumns = [
    {
      title: intl('JOB_NUMBER'),
      dataIndex: 'empId',
      key: 'empId',
      width: 20,
      ellipsis: true,
      render: (text, record) => (
        <Tooltip title={text}>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: intl('USERNAME'),
      dataIndex: 'name',
      key: 'name',
      width: 20,
      ellipsis: true,
      render: (text, record) => (
        <Tooltip title={text}>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: intl('REPORT_TO_JOB_NUMBER'),
      dataIndex: 'managerId',
      key: 'managerId',
      width: 20,
      ellipsis: true,
      render: (text, record) => (
        <Tooltip title={text}>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: intl('REPORT_TO'),
      dataIndex: 'managerName',
      key: 'managerName',
      width: 20,
      ellipsis: true,
      render: (text, record) => (
        <Tooltip title={text}>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: intl('EMPLOYEE_TYPE'),
      dataIndex: 'empClassTypeName',
      key: 'empClassTypeName',
      width: 20,
      ellipsis: true,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <Tooltip title={text}>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: intl('EMPLOYEE_NATURE'),
      dataIndex: 'empClassName',
      key: 'empClassName',
      width: 20,
      ellipsis: true,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <Tooltip title={text}>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: intl('DEPARTMENT_PATH'),
      dataIndex: 'deptNamePath',
      key: 'deptNamePath',
      ellipsis: true,
      width: 40,
      render: (text, record) => (
        <Tooltip title={text}>
          <span>{showEllipseWord(text, '>', false)}</span>
        </Tooltip>
      ),
    },
    {
      title: intl('ENTRY_DATE'),
      dataIndex: 'lastHireDate',
      key: 'lastHireDate',
      width: 20,
      ellipsis: true,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <Tooltip title={text}>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    // {
    //   title: intl('DETAIL'),
    //   dataIndex: 'file',
    //   key: 'file',
    //   width: 20,
    //   fixed: 'right',
    //   ellipsis: true,
    //   render: (text, record) => (
    //     <a
    //       href={``}
    //       rel="noreferrer"
    //       target="_blank"
    //       className="theme-text"
    //     >
    //       {intl('DETAIL')}
    //     </a>
    //   ),
    // },
  ];

  // keyword, //姓名，ldap，工号（精确搜索）
  // deptCodes, // 部门code集合
  // emplClassList, // 员工性质集合
  // jobFunctionList, // 大序列集合
  // jobSubFuncList, // 小序列集合
  // jobLevelList, // 职级集合
  // hireDt, // 入职日期
  // companyList, // 合同主体集合
  // locationList, // 工作地集合
  // pageNum, // 页码
  // pageSize, //数量

  const fillterList = [
    {
      title: intl('USERNAME_JOB_NUMBER'),
      name: 'keyword',
      component: (props) => {
        const url = pageCode === 'hr-workspace'
          ? `${apiHost}/gateway/dhr-workbench/dept/permissions/listByHrbpId?functionId=${authId}`
          : `${apiHost}/gateway/dhr-workbench/dept/permissions/list?functionId=${authId}`;
        return (
          <PeopleSelectWithAuth
            style={{ width: '100%' }}
            authUrl={url}
            authId={authId}
            {...props}
            hrStatus="A"
            empClass="CTR,INT,CLB,RLB"
            labelInValue
            noCopyableTag
            valueSelectKey="empId"
            headers={newHeaders}
          />
        );
      },
      copyableTag: true,
      onValueChangeHide: true,
    },
    {
      title: intl('DEPARTMENT'), // label
      name: 'deptCodes', // key
      component: 'DeptmentSelect',
      mode: 'multiple',
      dataSource: [],
      functionId: authId,
      params, // 传入权限
      copyableTag: true,
      labelInValue: true,
    },
    {
      title: intl('EMPLOYEE_NATURE'),
      name: 'emplClassList',
      component: 'Select',
      dataSource: [],
      mode: 'multiple',
      optionFilterProp: 'children',
    },
    {
      title: intl('LARGE_SEQUENCE'),
      name: 'jobFunctionList',
      component: 'Select',
      dataSource: [],
      mode: 'multiple',
      optionFilterProp: 'children',
      // open: true
      // searchUrl: '/dict/big/seq/type',//如果需要远程请求
      // searchKey:'keyword',//请求的参数
    },
    {
      title: intl('SMALL_SEQUENCE'), // label
      name: 'jobSubFuncList', // key
      component: 'Select', // 组件
      mode: 'multiple',
      disabled: smallJobCategoryDisable,
      dataSource: [], // select组件需要datasource
      optionFilterProp: 'children',
    },
    {
      title: intl('RANK'),
      name: 'jobLevelList',
      component: 'Select',
      dataSource: [],
      mode: 'multiple',
      optionFilterProp: 'children',
    },
    {
      title: intl('LABOR_CONTRACT_WORKPLACE'),
      name: 'locationList',
      component: 'Select',
      dataSource: [],
      mode: 'multiple',
      optionFilterProp: 'children',
    },
    {
      title: intl('CONTRACT_SUBJECT'),
      name: 'companyList',
      component: 'Select',
      dataSource: [{}],
      mode: 'multiple',
      optionFilterProp: 'children',
    },
    {
      title: intl('JOINED_DATE'),
      name: 'date',
      component: 'RangePicker',
      autoFocus: true,
      allowEmpty: [true, true],
      startKey: 'startHireDt',
      endKey: 'endHireDt',
    },
  ];

  // 工时制外包
  const outSourcingFilterList = [
    {
      title: intl('USERNAME_JOB_NUMBER'),
      name: 'empId',
      component: (props) => {
        const url = pageCode === 'hr-workspace'
          ? `${apiHost}/gateway/dhr-workbench/dept/permissions/listByHrbpId?functionId=${authId}`
          : `${apiHost}/gateway/dhr-workbench/dept/permissions/list?functionId=${authId}`;
        return (
          <PeopleSelectWithAuth
            style={{ width: '100%' }}
            authUrl={url}
            authId={authId}
            {...props}
            hrStatus="A"
            empClass="CTR,INT,CLB,RLB"
            labelInValue
            noCopyableTag
            valueSelectKey="empId"
            headers={newHeaders}
            type="out"
          />
        );
      },
      copyableTag: true,
      onValueChangeHide: true,
    },
    {
      title: intl('DEPARTMENT'), // label
      name: 'deptCodes', // key
      component: 'DeptmentSelect',
      mode: 'multiple',
      dataSource: [],
      functionId: authId,
      params, // 传入权限
      copyableTag: true,
      labelInValue: true,
    },
    {
      title: intl('EMPLOYEE_NATURE'),
      name: 'empClass',
      component: 'Select',
      dataSource: [],
      mode: 'multiple',
      optionFilterProp: 'children',
    },
    {
      title: intl('EMPLOYEE_TYPE'),
      name: 'empClassType',
      component: 'Select',
      dataSource: [],
      mode: 'multiple',
      optionFilterProp: 'children',
    },
    {
      title: intl('ENTRY_DATE'),
      name: 'lastHireDate',
      component: 'RangePicker',
      autoFocus: true,
      allowEmpty: [true, true],
      startKey: 'lastHireDateBegin',
      endKey: 'lastHireDateEnd',
    },
  ];

  // 获取权限部门
  const getAuthDept = async () => {
    if (!authId) return;
    const url = pageCode === 'hr-workspace'
      ? `${apiHost}/gateway/dhr-workbench/dept/permissions/listByHrbpId?functionId=${authId}`
      : `${apiHost}/gateway/dhr-workbench/dept/permissions/list?functionId=${authId}`;
    const res = await request(url);
    if (res.code === '100200') {
      const { data } = res;
      const param = {
        appId: data.appId,
        currentLdap: data.currentLdap,
        deptIds: data.deptIds,
      };
      setParams(param);
      // 暂存deptids 要来调用列表接口；
      if (data?.deptIds?.length > 0) {
        const newDepts = data.deptIds.filter((item, index) => data.deptIds.indexOf(item) === index);
        hasOnlyOneAuthDept.current = newDepts;
      }
    }
  };

  useEffect(() => {
    startLoadTime = new Date();
    getAuthDept();
    let width = 0;
    columns.map((v) => {
      width += v.width;
    });
    setTableWidth(width);
  }, []);

  const getJobLevelList = (jobFunctionListVal, jobSubFuncListVal, callBack) => {
    let params = {};
    if (jobFunctionListVal && jobSubFuncListVal) {
      params = {
        bigSeqCode: jobFunctionListVal,
        smallSeqCode: jobSubFuncListVal,
      };
    }
    request(`${apiHost}/gateway/dhr-workbench/dict/new/job/grade/type`, { params }).then(({ data }) => {
      callBack
        && callBack(
          data?.map((v) => ({
            label: v.value,
            value: v.key,
          })),
        );
    });
  };

  const service = async (data) => {
    const {
      values, pagination, sorter = {}, filters = {},
    } = data;

    const orderTemp = sorter?.order === 'descend' ? 'desc' : 'asc';
    const sortParams = {
      deptPathSort:
        sorter.field === 'deptPath' && sorter.order ? orderTemp : undefined,
      empIdSort:
        sorter.field === 'emplid' && sorter.order ? orderTemp : undefined,
      hireDateSort:
        sorter.field === 'deptEntryDt' && sorter.order ? orderTemp : undefined,
      rankSort:
        sorter.field === 'supvLvlIdDescr' && sorter.order ? orderTemp : undefined
    };

    const orderList = [];
    if (sorter && sorter?.field) {
      if (sorter.field === 'empClassTypeName') {
        orderList.push({
          field: "emp_class_type",
          order: sorter?.order ? orderTemp : undefined
        });
      }
      if (sorter.field === 'empClassName') {
        orderList.push({
          field: "emp_class",
          order: sorter?.order ? orderTemp : undefined
        });
      }
      if (sorter.field === 'lastHireDate') {
        orderList.push({
          field: "last_hire_date",
          order: sorter?.order ? orderTemp : undefined
        });
      }
    }
    const sortOutSourcingParams = { orderlist: orderList };

    const paramsValue = isOutSourcing ? FormFilter.dealWithValue(outSourcingFilterList, values) : FormFilter.dealWithValue(fillterList, values);

    if (paramsValue.keyword) {
      paramsValue.keyword = paramsValue.keyword.key;
    }
    if (paramsValue.empId) {
      paramsValue.empId = paramsValue.empId.key;
    }

    // 请求参数饿类型转换  未在changeArr数组里则不需要转换
    const changeValuesType = {};
    const changeArr = ['emplClassList', 'empClass', 'empClassType', 'companyList', 'jobFunctionList', 'jobLevelList', 'jobSubFuncList', 'locationList', 'deptCodes'];
    Object.keys(paramsValue).map((key) => {
      if (!changeArr.includes(key) || key === 'keyword') {
        changeValuesType[key] = paramsValue[key];
        return;
      }
      changeValuesType[key] = paramsValue[key]?.split(',');
    });
    if (values.deptCodes?.length === 0) changeValuesType.deptCodes = [];
    if (values.emplClassList?.length === 0) changeValuesType.emplClassList = [];
    if (values.empClass?.length === 0) changeValuesType.empClass = [];
    if (values.empClassType?.length === 0) changeValuesType.empClassType = [];
    if (values.jobFunctionList?.length === 0) { changeValuesType.jobFunctionList = []; }
    if (values.jobSubFuncList?.length === 0) { changeValuesType.jobSubFuncList = []; }
    if (values.jobLevelList?.length === 0) changeValuesType.jobLevelList = [];
    if (values.companyList?.length === 0) changeValuesType.companyList = [];
    if (values.locationList?.length === 0) changeValuesType.locationList = [];
    changeValuesType.deptCodes = paramsValue.deptCodes ? paramsValue.deptCodes.split(',') : deptInfos[0].allDeptList;
    if (isOutSourcing) {
      changeValuesType.empClass = paramsValue?.empClass ? paramsValue?.empClass.split(',') : [];
      changeValuesType.empClassType = paramsValue?.empClassType ? paramsValue?.empClassType.split(',') : [];
    }

    let reqData = {
      pageSize: pagination.pageSize,
      pageNum: pagination.current,
      ...changeValuesType,
      ...filters
    };

    if (isOutSourcing) {
      reqData = { ...reqData, ...sortOutSourcingParams };
    } else {
      reqData = { ...reqData, ...sortParams };
    }
    setParamsValue(reqData);

    // 传入页面数据
    const pageType = pageCode !== 'hr-workspace' ? 'management' : pageCode;
    const requestParams = {
      method: 'post',
      url: isOutSourcing ? `${apiHost}/gateway/bricks/api/unit/statistics/page/outsourcing/manhour/employees` : `${apiHost}/gateway/bricks/api/unit/statistics/page/team/members`,
      encryption: true, // 需要加密写
      data: reqData
    };

    if (authId) {
      return request(requestParams).then(({ code, data }) => {
        if (code === '100200' && data) {
          const {
            list = [], pageNum, pageSize, total, pages,
          } = data;
          let tableData = list;
          tableData = tableData?.map((v, i) => {
            if (v.empClass) {
              return {
                ...v,
                empClassName: outSourcingEmployeeNatureMap[v.empClass],
                empClassTypeName: outSourcingEmployeeTypeMap[v.empClassType]
              }
            } else {
              return {
                ...v,
                id: i,
              }
            }
          });

          setPostData({
            ...changeValuesType,
            pageCode: pageType,
            pageNum: 1,
            pageSize: total || 10,
            functionId: authId,
          });
          return {
            dataSource: tableData,
            ...pagination,
            pageNum,
            total,
          };
        }
        return {
          dataSource: [],
          ...pagination,
          total: 0,
        };
      }).finally(() => {
        if (isFirstFetch) {
          recordPageFMP(new Date() - startLoadTime);
          setIsFirstFetch(false);
        }
      });
    }
  };

  const submitResetMiddleware = () => ({ context }) => ({
    onFormResetQuery(payload, next) {
      context.setPagination({
        ...context.pagination,
        current: 1,
      });
      return next(payload);
    },
    onFormSubmitQuery(payload, next) {
      context.setPagination({
        ...context.pagination,
        current: 1,
      });
      return next(payload);
    },
  });

  const matchFieldValueMiddleware = (pattern, value) => ({ context }) => ({
    onFormSubmitQuery(payload, next) {
      context.setPagination({
        ...context.pagination,
        current: 1,
      });
      if (payload.jobFunctionList?.length <= 0) {
        payload.jobSubFuncList = [];
      }
      return next(payload);
    },
    onFormResetQuery(payload, next) {
      context.setPagination({
        ...context.pagination,
        current: 1,
      });
      return next(payload);
    },
  });

  const { form, table } = useFormTableQuery(
    service,
    [
      submitResetMiddleware(),
      matchFieldValueMiddleware('gender', 'male'),
      ({ actions }) => ({
        onFormInit() {
          if (!isOutSourcing) {
            // 大序列
            request(`${apiHost}/gateway/dhr-workbench/dict/new/big/seq/type`).then(({ code, data }) => {
              if (code === '100200') {
                actions.setFieldState('jobFunctionList', (state) => {
                  FormPath.setIn(
                    state,
                    'props.dataSource',
                    data.map((v) => ({
                      label: v.value,
                      value: v.key,
                    })),
                  );
                });
              }
            });
          }

          // 人员性质 (员工性质)
          if (isOutSourcing) {
            actions.setFieldState('empClass', (state) => {
              FormPath.setIn(
                state,
                'props.dataSource',
                outSourcingEmployeeNature
              );
            });
          } else {
            request(`${apiHost}/gateway/dhr-workbench/dict/personnel/nature/type`).then(({ code, data }) => {
              if (code === '100200') {
                actions.setFieldState('emplClassList', (state) => {
                  FormPath.setIn(
                    state,
                    'props.dataSource',
                    data.map((v) => ({
                      label: v.value,
                      value: v.key,
                    })),
                  );
                });
              }
            });
          }

          // 员工类型
          if (isOutSourcing) {
            actions.setFieldState('empClassType', (state) => {
              FormPath.setIn(
                state,
                'props.dataSource',
                outSourcingEmployeeType
              );
            });
          }

          if (!isOutSourcing) {
            // 职级
            getJobLevelList('', '', (arr) => {
              actions.setFieldState('jobLevelList', (state) => {
                // state.value = []
                FormPath.setIn(state, 'props.dataSource', arr);
              });
            });

            // 劳动合同工作地
            request(`${apiHost}/gateway/dhr-workbench/dict/laborContract/workplace/type`).then(
              ({ code, data }) => {
                if (code === '100200') {
                  actions.setFieldState('locationList', (state) => {
                    FormPath.setIn(
                      state,
                      'props.dataSource',
                      data.map((v) => ({
                        label: v.value,
                        value: v.key,
                      })),
                    );
                  });
                }
              },
            );

            // 合同主体
            request(`${apiHost}/gateway/dhr-workbench/dict/contract/subject/type`).then(({ code, data }) => {
              if (code === '100200') {
                actions.setFieldState('companyList', (state) => {
                  FormPath.setIn(
                    state,
                    'props.dataSource',
                    data.map((v) => ({
                      label: v.value,
                      value: v.key,
                    })),
                  );
                });
              }
            });
          }
        },
      }),
      ({ actions }) => ({
        async onFieldValueChange(state) {
          // 互动逻辑，动态获取选项
          if (state.name === 'jobFunctionList') {
            const jobFunctionListVal = state.value
              ?.map((v) => v.value)
              .join(',');

            setSmallJobCategoryDisable(!jobFunctionListVal);
            const { data } = await request(
              `${apiHost}/gateway/dhr-workbench/dict/new/small/seq/type?bigSeqCode=${jobFunctionListVal}`,
            );

            actions.setFieldState('jobSubFuncList', (state) => {
              state.value = [];
              FormPath.setIn(
                state,
                'props.dataSource',
                data.map((v) => ({
                  label: v.value,
                  value: v.key,
                })),
              );
              FormPath.setIn(state, 'props.disabled', !jobFunctionListVal);
            });
          }
        },
      }),
      ({ actions, context }) => ({
        async onFormFirstQuery(payload, next) {
          const pageValue = emplValue;
          const pageLabel = emplLabel;
          const dhrMembers = deptInfos;

          // 设置人员性质默认值
          if (pageValue) {
            if (isOutSourcing) {
              // 不用默认选中人员性质
              // actions.setFieldState('empClass', (state) => {
              //   state.value = [{ value: pageValue, label: pageLabel }];
              // });
            } else {
              // pageValue存在表示从团队信息页面点进来
              actions.setFieldState('emplClassList', (state) => {
                state.value = [{ value: pageValue, label: pageLabel }];
              });
            }
          }

          // 设置部门默认值
          // dhrMembers存在表示从团队信息页面点进来
          if (dhrMembers !== '' && deptCodes) {
            actions.setFieldState('deptCodes', (state) => {
              state.value = dhrMembers;
            });
          }

          const nextData = {
            ...payload,
          };
          if (pageValue) {
            nextData.emplClassList = [{ value: pageValue, label: pageLabel }];
          }
          if (isOutSourcing) {
            // nextData.empClass = [{ value: pageValue, label: pageLabel }];
            nextData.empClass = [];
          }
          if (dhrMembers) {
            nextData.deptCodes = dhrMembers;
          } else if (hasOnlyOneAuthDept.current) {
            nextData.deptCodes = [hasOnlyOneAuthDept.current];
          }

          actions.dispatch('filterValueSet', actions.getFormState());
          return next(nextData);
        },
      }),
    ],
    {
      pagination: {
        pageSize: 10,
        showQuickJumper: true,
        showSizeChanger: true,
        pageSizeOptions: [10, 20, 50, 100],
      },
    },
  );

  form.actions = fitteractions;

  const prefixCls = 'pagelet-table-panel';
  const wrapSSR = useStyle(prefixCls);

  return wrapSSR(
    <ConfigProvider prefixCls="ant5" locale={langEn ? en_US : zh_CN}>
      <div className="pagelet-table-panel" style={{ minHeight: '400px', height: '100%', overflowY: 'auto' }}>
        <div className="export-button-view">
            <div className='export-button-view-search' onClick={()=>{setsearchCriteria(!searchCriteria)}}>
              {`${ searchCriteria ? '收起' : '展开' }搜索条件`}
              {searchCriteria ? <UpThickOutlined /> : <DownThickOutlined /> }
            </div>
            <ExportButton
              data={postData}
              authId={authId}
              intl={intl}
              url={isOutSourcing ? `${apiHost}/gateway/bricks/api/unit/statistics/export/outsourcing/manhour/employees` :`${apiHost}/gateway/bricks/api/unit/statistics/export/team/members`}
              defaultFile={isOutSourcing ? intl('EXPORT_WORK_HOURS_OUTSOURCING_LIST') : isCTR ? intl('EXPORT_REGULAR_EMPLOYEE_LIST') : intl('EXPORT_INTERN_LIST')}
              headers={newHeaders}
            />
        </div>
        <div className={
          searchCriteria
            ? 'members-search-body-show'
            : 'members-search-body-hide'
        }>
          {params && (
            <FormFilter
              form={form}
              filter={isOutSourcing ?  outSourcingFilterList : fillterList}
              total={table?.pagination?.total}
              intl={intl}
              headers={newHeaders}
            />
          )}
        </div>
        <div className="table-container">
          <div className='table-wrapper'>
            <EmptyRender intl={intl}>
              <DHRTable
                {...table}
                locale={langEn ? en_US : zh_CN}
                // size="small"
                columns={isOutSourcing ? outSourcingColumns : columns}
                scroll={{
                  x: isOutSourcing ? 1400 : 1600,
                  y: isOutSourcing ? 'calc(100vh - 340px)' : 'calc(100vh - 360px)',
                 }}
                rowKey={(record) => record.id}
                showQuickJumper
              />
            </EmptyRender>
          </div>
        </div>
      </div>
    </ConfigProvider>
  );
};

export default Members;
