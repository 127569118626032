import { useRegisterStyle } from '@didi/ec-base';


const useStyle = (prefixCls) => {

	return useRegisterStyle('page-table-panel', () => [
		{
			// base
      '.pagelet-table-panel': {
        position: 'relative',
        background: '#ffffff',
        '.export-button-view': {
            // height: '50px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
            marginBottom: '16px',
            '.export-button-view-search': {
              fontSize: '14px',
              fontWeight: 'normal',
              lineHeight: '22px',
              letterSpacing: '0em',
              color: 'rgba(0, 0, 0, 0.6)',
              display: 'flex',
              alignItems: 'center',
              marginRight: '16px',
              '.up-thick-outlined-icons, .down-thick-outlined-icons': {
                lineHeight: '14px',
                marginLeft: '4px',
              }
            },
          },
        '.members-search-body-show': {
          display: 'block',
          marginBottom: '16px'
        },
        '.members-search-body-hide': {
          display: 'none'
        },
        '.table-container': {
          borderRadius: '8px',
          backgroundColor: '#ffffff',

          '.table-wrapper': {
            ['.ant5-table-expanded-row-fixed .ec-ehr-base-table-empty,.ec-ehr-base-table-empty']: {
              marginBlock: '96px',
              position: 'sticky',
              left: '0',
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              ['img']: {
                  display: 'inline-block',
                  width: '180px'
              },
              ['.ec-ehr-base-table-empty-text']: {
                  fontSize: '12px',
                  color: '#2D3040',
                  letterSpacing: '0',
                  lineHeight: '14px',
              }
          }
          },
          '.theme-text': {
            color: '#FF6400 !important',
          },
        }
      },
      '.team-members-probation-info': {
        display: 'grid',
        gridTemplateColumns: '24px auto',
        alignItems: 'center',
        '.is-probation': {
          position: 'relative',
          left: '-20px',
          top: '-6px',
          display: 'block',
          fontSize: '20px',
          color: '#4070F4',
          background: 'rgba(71, 117, 244, 0.1)',
          padding: '0 4px',
          borderRadius: '5px',
          transform: 'scale(0.5)',
        }
      }
		}
	])
};

export default useStyle;
